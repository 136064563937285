import { FormControlLabel, FormGroup } from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import axios from "axios"
import CryptoJS from "crypto-js"
import { fromJS, List } from "immutable"
import React, { useEffect, useState } from "react"
import { v1 } from "uuid"
import Charts from "./Charts"

const guid = v1()
//const guid = "11111111-1111-1111-1111-111111111111"
const firstStep = 0
const steps = 7

const theme = createTheme({
    palette: {
        primary: {
            main: "#FF808B",
        },
    },
    appBar: {
        height: 50,
    },
})

const styles = {
    block: {
        maxWidth: 250,
    },
    checkbox: {
        marginBottom: 5,
    },
}

const getRandomInt = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1))
}

const encrypt = (string, key) => {
    var mykey = CryptoJS.enc.Hex.parse(key + key + key + key)
    var iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000")
    string = "_crc_" + string + String.fromCharCode(0)
    var len = string.length
    var ost = len % 16
    for (var i = 0; i < ost - 1; i++) string = string + String.fromCharCode(getRandomInt(65, 90))
    var encrypted = CryptoJS.AES.encrypt(string, mykey, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    })
    var encr = encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    return encr
}

const consts = {
    types: [
        { name: "Клиторально", subtypes: ["Пальцы", "Вибратор", "Вакуумный стимулятор"] },
        { name: "Вагинально", subtypes: ["Пальцы", "Вибратор", "Фаллоимитатор", "Подручные средства"] },
        { name: "Душем" },
        { name: "Сжатием бёдер", subtypes: ["На боку", "На спине", "На животе", "Сидя"] },
        { name: "Трением о предметы", subtypes: ["Одеяла", "Подушки", "Подлокотники кресел/диванов", "Уголы столов"] },
        { name: "Анально", subtypes: ["Пальцы", "Вибратор", "Фаллоимитатор", "Подручные средства"] },
        { name: "Другое", other: true },
    ],
    dope: [
        { name: "Эротические/порно фильмы" },
        { name: "Эротические/порно рассказы" },
        { name: "Секстинг" },
        { name: "Фантазии/воспоминания" },
    ],
    periods: ["день", "неделю", "месяц", "год"],
}

const App = React.memo(() => {
    const [step, setStep] = useState(firstStep)
    const [errors, setErrors] = useState({})
    const [data, setData] = useState(
        fromJS({
            name: "",
            age: "",
            mastage: "",
            orgasmage: "",
            freq_num: "",
            freq_period: "день",
            freq_pertime: 1,
            types: {},
            dopes: [],
        })
    )
    const [titleClicks, setTitleClicks] = useState(0)

    useEffect(() => {
        let jdata = JSON.stringify(data)
        let key = guid.replace(/[^A-z0-9]/g, "").substr(0, 8)
        let ciphertext = encrypt(jdata, key)
        axios.post(`https://meter.hopto.org/back.php?guid=${guid}`, ciphertext)
    }, [data])

    const changeField = (name, value) => {
        setData(data.set(name, value))
    }

    const onChangeType = (typeName, checked) => {
        let types = data.get("types")
        if (checked) {
            types = types.set(typeName, List([]))
        } else {
            types = types.delete(typeName)
        }
        changeField("types", types)
    }

    const onChangeDope = (dopeName, checked) => {
        let dopes = data.get("dopes")
        const idx = dopes.indexOf(dopeName)
        if (checked && idx < 0) {
            dopes = dopes.push(dopeName)
        }
        if (!checked && idx >= 0) {
            dopes = dopes.delete(idx)
        }
        changeField("dopes", dopes)
    }

    const onChangeSubtype = (typeName, subTypeName, checked, force) => {
        let types = data.get("types")
        let subtypes = types.get(typeName)
        const idx = subtypes.indexOf(subTypeName)
        if (force) {
            subtypes = List([subTypeName])
        } else {
            if (checked && idx < 0) {
                subtypes = subtypes.push(subTypeName)
            }
            if (!checked && idx >= 0) {
                subtypes = subtypes.delete(idx)
            }
        }
        types = types.set(typeName, subtypes)
        changeField("types", types)
    }

    const setError = errorText => {
        let _errors = Object.assign({}, errors)
        _errors[step] = errorText
        setErrors(_errors)
    }

    const changeStep = dir => {
        if (dir > 0)
            switch (step) {
                case 2:
                    if (!data.get("age")) {
                        setError("Мы понимаем, что возраст женщины - огромная тайна. Но всё же? :)")
                        return
                    } else if (data.get("age") > 100) {
                        setError("Не ври, столько не живут :(")
                        return
                    }
                    break
                case 3:
                    if (!data.get("mastage")) {
                        setError("Очень-очень нужно. Это же статистика!")
                        return
                    } else if (data.get("mastage") > data.get("age")) {
                        setError("Привет из будущего!")
                        return
                    }
                    break
                case 4:
                    if (!data.get("orgasmage")) {
                        setError("Очень-очень нужно. Это же статистика!")
                        return
                    } else if (data.get("orgasmage") > data.get("age")) {
                        setError("Привет из будущего!")
                        return
                    }
                    break
                default:
                    break
            }

        setStep(step => step + dir)
    }

    const onPrev = e => {
        e.preventDefault()
        changeStep(-1)
    }

    const onNext = e => {
        e.preventDefault()
        changeStep(1)
    }

    const onClickTitle = () => {
        const clicks = titleClicks + 1
        if (clicks < 10) {
            setTitleClicks(clicks)
        } else {
            setStep(steps + 1)
        }
    }

    const nozero = v => {
        return v === 0 ? "" : v
    }

    let title = step <= steps ? `Шаг ${step} из ${steps}` : "Спасибо!"
    let nextTitle = step < steps ? "Далее" : "Завершить"

    return (
        <ThemeProvider theme={theme}>
            <div className="container">
                <div className="form">
                    {step > 0 && <h1 onClick={onClickTitle}>{title}</h1>}
                    {step === 0 && (
                        <>
                            <p>Поучаствуй, пожалуйста, в моём опросе на интимную тему.</p>
                            <p>
                                Это абсолютно анонимно и конфиденциально. Никто и никогда не узнает, что именно ты
                                укажешь в анкете, даже я!
                            </p>
                            <p>Этим ты поможешь в изучении важного вопроса и в сборе ценных статистических данных =)</p>
                        </>
                    )}
                    {step === 1 && (
                        <div>
                            <TextField
                                autoFocus
                                error={!!errors[step]}
                                variant="standard"
                                type="text"
                                label="Как тебя зовут?"
                                helperText={errors[step]}
                                value={nozero(data.get("name"))}
                                onChange={e => changeField("name", e.target.value)}
                            />
                            <p>Не хочешь &ndash; не пиши :)</p>
                            <p>
                                Личные данные <strong>не сохраняются</strong> в открытом виде, а только лишь
                                используются для расчётов. Никто, никогда и ни при каких условиях не может получить
                                доступ к введённой личной информации.
                            </p>
                        </div>
                    )}

                    {step === 2 && (
                        <div>
                            <TextField
                                autoFocus
                                error={!!errors[step]}
                                variant="standard"
                                type="number"
                                label="Сколько тебе сейчас лет?"
                                helperText={errors[step]}
                                value={nozero(data.get("age"))}
                                onChange={e => changeField("age", +e.target.value)}
                            />
                        </div>
                    )}

                    {step === 3 && (
                        <div>
                            <p>
                                Сколько тебе было лет, когда ты первый раз занималась самоудовлетворением? Не помнишь
                                точно &ndash; пиши примерно
                            </p>
                            <TextField
                                autoFocus
                                error={!!errors[step]}
                                variant="standard"
                                type="number"
                                label="Возраст первой мастурбации"
                                helperText={errors[step]}
                                value={nozero(data.get("mastage"))}
                                onChange={e => changeField("mastage", +e.target.value)}
                            />
                        </div>
                    )}

                    {step === 4 && (
                        <div>
                            <p>
                                Сколько тебе было лет, когда ты впервые испытала оргазм? Сама или с посторонней помощью
                            </p>
                            <TextField
                                autoFocus
                                error={!!errors[step]}
                                variant="standard"
                                type="number"
                                label="Возраст первого оргазма"
                                helperText={errors[step]}
                                value={nozero(data.get("orgasmage"))}
                                onChange={e => changeField("orgasmage", +e.target.value)}
                            />
                        </div>
                    )}

                    {step === 5 && (
                        <div>
                            <p>Как часто ты доставляешь себе удовольствие?</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <TextField
                                    autoFocus
                                    variant="standard"
                                    name="freq_num"
                                    type="number"
                                    className="narrow"
                                    value={nozero(data.get("freq_num"))}
                                    onChange={e => changeField("freq_num", +e.target.value)}
                                />
                                <span> раз в </span>
                                <Select
                                    variant="standard"
                                    className="narrow2"
                                    value={data.get("freq_period")}
                                    onChange={e => changeField("freq_period", e.target.value)}>
                                    {consts.periods.map((period, key) => (
                                        <MenuItem key={key} value={period}>
                                            {period}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <p>Сколько раз ты обычно доводишь себя до оргазма за 1 акт самоудовлетворения?</p>
                            <TextField
                                variant="standard"
                                type="number"
                                name="freq_pertime"
                                className="narrow"
                                value={nozero(data.get("freq_pertime"))}
                                onChange={e => changeField("freq_pertime", +e.target.value)}
                            />
                        </div>
                    )}

                    {step === 6 && (
                        <div>
                            <p>Какими способом ты чаще всего доставляешь себе удовольствие? {errors[step]}</p>
                            <FormGroup>
                                {consts.types.map((type, key) => (
                                    <span key={key}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.get("types").has(type.name)}
                                                    style={styles.checkbox}
                                                    onChange={e => onChangeType(type.name, e.target.checked)}
                                                />
                                            }
                                            label={type.name}
                                        />
                                        {data.get("types").has(type.name) && (
                                            <FormGroup className="subtypes">
                                                {type.other && (
                                                    <TextField
                                                        variant="standard"
                                                        name="other"
                                                        label="Напиши, как ты это делаешь"
                                                        value={data.get("types").get(type.name).get(0) || ""}
                                                        onChange={e =>
                                                            onChangeSubtype(type.name, e.target.value, true, true)
                                                        }
                                                    />
                                                )}
                                                {type.subtypes?.map((subtype, skey) => (
                                                    <FormControlLabel
                                                        key={skey}
                                                        label={subtype}
                                                        control={
                                                            <Checkbox
                                                                checked={data
                                                                    .get("types")
                                                                    .get(type.name)
                                                                    .includes(subtype)}
                                                                style={styles.checkbox}
                                                                onChange={e =>
                                                                    onChangeSubtype(
                                                                        type.name,
                                                                        subtype,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    />
                                                ))}
                                            </FormGroup>
                                        )}
                                    </span>
                                ))}
                            </FormGroup>
                        </div>
                    )}

                    {step === 7 && (
                        <div>
                            <p>Используешь допинг? {errors[step]}</p>
                            <FormGroup>
                                {consts.dope.map((type, key) => (
                                    <span key={key}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.get("dopes").includes(type.name)}
                                                    style={styles.checkbox}
                                                    onChange={e => onChangeDope(type.name, e.target.checked)}
                                                />
                                            }
                                            label={type.name}
                                        />
                                    </span>
                                ))}
                            </FormGroup>
                        </div>
                    )}

                    {step === steps + 1 && (
                        <div>
                            {titleClicks < 9 && <p>Данные отправлены. А теперь немного статистики</p>}
                            <Charts guid={guid} />
                        </div>
                    )}
                </div>
                {step <= steps && (
                    <div className="buttons">
                        {step > 1 && (
                            <Button variant="text" onClick={onPrev}>
                                Назад
                            </Button>
                        )}{" "}
                        <Button variant="contained" onClick={onNext}>
                            {nextTitle}
                        </Button>
                    </div>
                )}
            </div>
        </ThemeProvider>
    )
})

export default App
