import axios from "axios"
import CryptoJS from "crypto-js"
import React, { Component } from "react"
import { Bar, BarChart, CartesianGrid, LabelList, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

class Charts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guid: props.guid,
            typesText: [
                "Анальная",
                "Вагинальная",
                "Душ",
                "Клиторальная",
                "Секс-игрушки",
                "Сжатие ног",
                "Трение о предметы",
                "Смешанная",
            ],
            first: [],
            types: [],
            ages: [],
        }
    }

    decrypt = obj => {
        let passphrase = this.state.guid.replace(/[^A-z0-9]/g, "").substr(0, 8) + navigator.userAgent

        var encrypted = obj.ciphertext
        var salt = CryptoJS.enc.Hex.parse(obj.salt)
        var iv = CryptoJS.enc.Hex.parse(obj.iv)

        var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 })
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv })
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
    }

    componentDidMount = () => {
        const url = `https://meter.hopto.org/back.php?stats=1&guid=${this.state.guid}`
        axios
            .get(url)
            .then(data => {
                let decrypted = this.decrypt(data.data)
                let types = []
                let first = []
                let ages = []

                let _types = {}
                let _first = {}
                let _ages = {}
                decrypted.forEach(item => {
                    if (!_first[item.first]) _first[item.first] = 0
                    _first[item.first]++

                    if (!_types[item.type]) _types[item.type] = 0
                    _types[item.type]++

                    if (!_ages[item.age]) _ages[item.age] = []
                    _ages[item.age].push((item.freq / item.freqperdays) * item.pertime)
                })

                for (let age in _first) {
                    first.push({ age: parseInt(age, 10), num: _first[age] })
                }

                for (let type in _types) {
                    types.push({ type: this.state.typesText[type], num: _types[type] })
                }

                for (let age in _ages) {
                    let s = _ages[age].reduce((acc, cur) => acc + cur, 0)
                    ages.push({ age: parseInt(age, 10), freq: s / _ages[age].length })
                }

                this.setState({
                    first: first,
                    types: types.sort((a, b) => {
                        if (a.num < b.num) return 1
                        if (a.num > b.num) return -1
                        return 0
                    }),
                    ages: ages,
                })
            })
            .catch(e => console.log(e))
    }

    renderLabel = props => {
        const { x, y, width, height, value } = props
        const radius = 10

        return (
            <g>
                <circle cx={x + width - radius - 2} cy={y + height / 2} r={radius} stroke="#FF808B" fill="white" />
                <text
                    x={x + width - radius - 2}
                    y={y + height / 2 + 1}
                    fill="#FF808B"
                    textAnchor="middle"
                    dominantBaseline="middle">
                    {value}
                </text>
            </g>
        )
    }

    render() {
        if (!this.state.types) return ""
        return (
            <div>
                <h2>Способы</h2>
                <div style={{ width: "100%", height: "250px" }}>
                    <ResponsiveContainer>
                        <BarChart width={500} height={300} data={this.state.types} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" />
                            <YAxis type="category" dataKey="type" width={110} />
                            <Bar dataKey="num" fill="#FF808B">
                                <LabelList content={this.renderLabel} dataKey="num" />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <h2>Возраст начала</h2>
                <div style={{ width: "100%", height: "200px" }}>
                    <ResponsiveContainer>
                        <LineChart width={500} height={300} data={this.state.first}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="age" label={{ value: "Возраст", position: "bottom", offset: -8 }} />
                            <YAxis dataKey="num" label={{ value: "Кол-во", angle: -90, position: "insideLeft" }} />
                            <Line type="monotone" dataKey="num" stroke="#FF808B" strokeWidth={2} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <h2>Частота</h2>
                <div style={{ width: "100%", height: "200px" }}>
                    <ResponsiveContainer>
                        <LineChart width={500} height={300} data={this.state.ages}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                type="number"
                                dataKey="age"
                                domain={["dataMin", "dataMax"]}
                                tickCount={15}
                                label={{ value: "Возраст", position: "bottom", offset: -8 }}
                            />
                            <YAxis
                                type="number"
                                ticks={[0.5, 1, 1.5, 2]}
                                dataKey="freq"
                                interval="preserveStartEnd"
                                label={{ value: "Раз в день", angle: -90, position: "insideLeft" }}
                            />
                            <Line type="monotone" dataKey="freq" stroke="#FF808B" strokeWidth={2} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )
    }
}

export default Charts
